.header_top[data-v-87e83610] {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.record[data-v-87e83610] {
  font-size: 14px;
  color: #333;
  font-weight: 600;
  margin: 10px 0;
}
.record[data-v-87e83610]::before {
  content: "";
  background: var(--themeColor, #17a2b8);
  display: inline-block;
  width: 3px;
  height: 13px;
  position: relative;
  margin-right: 10px;
}
.w280[data-v-87e83610] {
  width: 280px !important;
}
.margin0[data-v-87e83610] {
  margin: 0 !important;
}
.w80[data-v-87e83610] {
  width: 80px !important;
}
.red[data-v-87e83610] {
  color: #f00;
  margin-right: 3px;
  font-size: 15px;
}
.del_btn[data-v-87e83610] {
  margin-left: 0;
  color: #fe696a;
}
[data-v-87e83610] .el-upload-dragger {
  padding: 2px 10px;
}
.steps[data-v-87e83610] {
  width: 100%;
  margin-top: 10px;
  background-color: #e7fcff;
  padding: 10px 16px;
  border-radius: 4px;
}
.step_item[data-v-87e83610] {
  --n-indicator-color: var(--themeColor, #17a2b8) !important;
  --n-indicator-border-color: var(--themeColor, #17a2b8) !important;
}
.step_success[data-v-87e83610] {
  --n-splitor-color: var(--themeColor, #17a2b8) !important;
  --n-indicator-border-color: var(--themeColor, #17a2b8) !important;
  --n-indicator-text-color: var(--themeColor, #17a2b8) !important;
}
.w520[data-v-87e83610] {
  width: 520px;
}