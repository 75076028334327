.header_top[data-v-0c6b3462] {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.record[data-v-0c6b3462] {
  font-size: 14px;
  color: #333;
  font-weight: 600;
  margin: 10px 0;
}
.record[data-v-0c6b3462]::before {
  content: "";
  background: var(--themeColor, #17a2b8);
  display: inline-block;
  width: 3px;
  height: 13px;
  position: relative;
  margin-right: 10px;
}
.w280[data-v-0c6b3462] {
  width: 280px !important;
}
.margin0[data-v-0c6b3462] {
  margin: 0 !important;
}
.w80[data-v-0c6b3462] {
  width: 80px !important;
}
.red[data-v-0c6b3462] {
  color: #f00;
  margin-right: 3px;
  font-size: 15px;
}
.del_btn[data-v-0c6b3462] {
  margin-left: 0;
  color: #fe696a;
}
[data-v-0c6b3462] .el-upload-dragger {
  padding: 2px 10px;
}