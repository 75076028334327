.page_container[data-v-2b592bda] {
  float: right;
  margin-top: 10px;
}
.top_select[data-v-2b592bda] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.hand_style[data-v-2b592bda] {
  color: #ff6633;
}